<template>
	<v-app>
		<LayoutHomeDrawer v-model="model" />
		<LayoutHomeAppbar v-model="model" />
		<v-main
			class="user_layout"
			:class="fluent_layout"
		>
			<router-view />
		</v-main>
		<CommonDialog
			:dialog="dialog.dialog"
			:icon="dialog.icon"
			:title="dialog.title"
			:text="dialog.text"
			@close="closeDialog()"
		/>
		<CommonLoading v-if="loading" />
	</v-app>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import localStorage from '@/utils/localStorage'

export default {
	name: 'User',
	data: () => ({
		model: false,
		fluent_layout: '',
	}),
	computed: {
		...mapGetters(['AUTH_GET_USER_AUTH', 'APP_GET_DIALOG', 'APP_GET_LOADING']),
		loading() {
			return this.APP_GET_LOADING
		},
		dialog() {
			return !this.APP_GET_DIALOG ? { dialog: false, icon: '', title: '', text: '' } : this.APP_GET_DIALOG
		},
		pageRouter() {
			return this.$route.name
		},
	},
	watch: {
		pageRouter(val) {
			if (val === 'User_List_Detail') {
				this.fluent_layout = 'v-main02'
			} else {
				this.fluent_layout = ''
			}
		},
	},
	async mounted() {
		if (this.AUTH_GET_USER_AUTH === 'GUEST') {
			if (localStorage.accessToken.get()) {
				await this.AUTH_ACT_GET_TOKEN('accessToken_confirm')
			} else {
				if (this.AUTH_GET_USER_AUTH === 'GUEST') {
					this.APP_MU_DIALOG({
						dialog: true,
						title: '마이페이지',
						text: '회원전용 페이지 입니다. 로그인 후 이용해 주세요.',
					})
					return this.$router.push('/login')
				}
			}
		}
	},
	methods: {
		...mapMutations(['APP_MU_DIALOG']),
		...mapActions(['AUTH_ACT_GET_TOKEN']),
		closeDialog() {
			this.APP_MU_DIALOG(null)
		},
	},
}
</script>

<style scoped lang="scss">
.v-main {
	padding: 116px 0 50px !important;
}
.v-main02 {
	padding: 116px 0 0px !important;
}
@media all and (max-width: 959px) {
	.v-main {
		padding: 48px 0 50px !important;
	}
	.v-main02 {
		padding: 48px 0 0px !important;
	}
}
</style>
